// Languages
export const LANGUAGES = {
  EN: 'en',
  TW: 'tw',
}
export const DEFAULT_LANGUAGE = LANGUAGES.EN
export const LANGUAGE_COOKIE_NAME = 'DTF_LANG'
export const LANGUAGE_COOKIE_EXPIRY_SECONDS = 86400 * 7 // seconds in day * 7

export const STICKY_BANNER_CLOSED_COOKIE_NAME = 'STICKY_BANNER_CLOSED'
export const STICKY_BANNER_CLOSED_COOKIE_EXPIRY_SECONDS = 86400 * 7 // seconds in day * 7

// Doc Types
export const DOC_TYPES = {
  PAGE: 'page',
  MENU_ITEM: 'menuItem',
  LOCATION: 'location',
  BLOG_POST: 'blogPost',
}

// Service Types
export const SERVICE_TYPES = {
  PICKUP: 'pickup',
  RESERVATIONS: 'reservations',
  CATERING: 'catering',
  DELIVERY: 'delivery',
  GENERAL_RESERVATION: 'generalReservation',
  DINING_ROOM: 'diningRoom',
  BAR_COUNTER: 'barCounter',
}

// Private Dining Section ID
export const PRIVATE_DINING_SECTION_ID_SUBSTRING = 'private_dining_location_section'

// Slugs / Paths
export const FOUR_OH_FOUR_SLUG = '404'
export const MENU_SLUG = 'menu'
export const LOCATIONS_SLUG = 'locations'
export const BLOG_SLUG = 'blog'

// Misc Text
export const MISC_TEXT = {}

// Event Names
export const EVENTS = {}

// Time Zones
export const TIME_ZONES = {
  ATLANTIC: {
    title: 'Atlantic',
    zone: 'America/St_Johns',
  },
  EASTERN: {
    title: 'Eastern',
    zone: 'America/New_York',
  },
  CENTRAL: {
    title: 'Central',
    zone: 'America/Chicago',
  },
  MOUNTAIN: {
    title: 'Mountain',
    zone: 'America/Denver',
  },
  PACIFIC: {
    title: 'Pacific',
    zone: 'America/Los_Angeles',
  },
  ALASKA: {
    title: 'Alaska',
    zone: 'America/Anchorage',
  },
  HAWAII: {
    title: 'Hawaii',
    zone: 'Pacific/Honolulu',
  },
  ARIZONA: {
    title: 'Arizona',
    zone: 'America/Phoenix',
  },
  INDIANA: {
    title: 'Indiana',
    zone: 'America/Indiana/Indianapolis',
  },
  MICHIGAN: {
    title: 'Michigan',
    zone: 'America/Detroit',
  },
  NORTH_DAKOTA: {
    title: 'North Dakota',
    zone: 'America/North_Dakota/Center',
  },
  SOUTH_DAKOTA: {
    title: 'South Dakota',
    zone: 'America/North_Dakota/New_Salem',
  },
  TENNESSEE: {
    title: 'Tennessee',
    zone: 'America/Chicago',
  },
  TEXAS: {
    title: 'Texas',
    zone: 'America/Chicago',
  },
  FLORIDA: {
    title: 'Florida',
    zone: 'America/New_York',
  },
  KENTUCKY: {
    title: 'Kentucky',
    zone: 'America/New_York',
  },
  KANSAS: {
    title: 'Kansas',
    zone: 'America/Chicago',
  },
  NEBRASKA: {
    title: 'Nebraska',
    zone: 'America/Chicago',
  },
  NEVADA: {
    title: 'Nevada',
    zone: 'America/Los_Angeles',
  },
  OREGON: {
    title: 'Oregon',
    zone: 'America/Los_Angeles',
  },
  WASHINGTON: {
    title: 'Washington',
    zone: 'America/Los_Angeles',
  },
  CALIFORNIA: {
    title: 'California',
    zone: 'America/Los_Angeles',
  },
  IDAHO: {
    title: 'Idaho',
    zone: 'America/Boise',
  },
  MONTANA: {
    title: 'Montana',
    zone: 'America/Denver',
  },
  WYOMING: {
    title: 'Wyoming',
    zone: 'America/Denver',
  },
  UTAH: {
    title: 'Utah',
    zone: 'America/Denver',
  },
  COLORADO: {
    title: 'Colorado',
    zone: 'America/Denver',
  },
  NEW_MEXICO: {
    title: 'New Mexico',
    zone: 'America/Denver',
  },
  PUERTO_RICO: {
    title: 'Puerto Rico',
    zone: 'America/Puerto_Rico',
  },
  VIRGIN_ISLANDS: {
    title: 'Virgin Islands',
    zone: 'America/St_Thomas',
  },
  GUAM: {
    title: 'Guam',
    zone: 'Pacific/Guam',
  },
  AMERICAN_SAMOA: {
    title: 'American Samoa',
    zone: 'Pacific/Pago_Pago',
  },
  NORTHERN_MARIANA_ISLANDS: {
    title: 'Northern Mariana Islands',
    zone: 'Pacific/Saipan',
  },
} as const

export type TimeZoneKey = keyof typeof TIME_ZONES;

export const DAY_ORDER = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
